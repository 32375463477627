export const environment = {
  production: false,
  environmentName: 'development',
  enabledServiceWorker: true,
  firebase: {
    apiKey: 'AIzaSyByopTtagJzuhjXwtkgz2aURaH9ctDueoo',
    authDomain: 'valhalla-shippers-dev.firebaseapp.com',
    projectId: 'valhalla-shippers-dev',
    storageBucket: 'valhalla-shippers-dev.appspot.com',
    messagingSenderId: '175154622282',
    appId: '1:175154622282:web:5694ba98c0f0e89a9d5bb3',
  },
  cargamos: {
    apiUrl: 'https://dev.services.shippers.cargamos.com',
    apiKey: 'AIzaSyBXw94azOUzt0H9ixI6q9HsN_kyHEzVyKc',
  },
  externalUrls: {
    cookiesLegalDocument: 'https://legal.cargamos.com/documento/aviso-de-privacidad-general',
  },
  sentry: {
    enabled: true,
    apiKey: 'https://ff008e107827460a9b8cdcad31333e0e@o474795.ingest.sentry.io/4504948572160000',
  },
  statics: {
    csvIntegrationTemplate:
      'gs://valhalla-shippers-dev.appspot.com/frontend-static-files/csv_integration_template.xlsx',
  },
};
