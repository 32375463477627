{
  "name": "frontend-admin-shippers",
  "version": "1.19.0",
  "scripts": {
    "ng": "ng",
    "start": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng serve",
    "start:production": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng serve --configuration=production",
    "watch": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --watch --configuration development",
    "test": "jest --maxWorkers=20%",
    "test:coverage": "npm run test -- --coverage",
    "test:watch": "npm run test -- --watchAll --coverage",
    "test:watch:coverage": "npm run test -- --coverage --no-cache --watchAll",
    "lint": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng lint",
    "lint:fix": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng lint --fix",
    "prettier:format": "prettier --write 'src/**/*.{ts,js,html,scss,json}'",
    "prettier:check": "prettier 'src/**/*.{ts,js,html,scss,json}' --check",
    "prepare": "husky install",
    "build:develop": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --configuration=development --aot",
    "build:production": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --configuration=production --aot",
    "build:qa:develop": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --configuration=development --aot",
    "build:qa:production": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng build --configuration=production --aot",
    "postinstall": "node --max_old_space_size=4096 node_modules/@angular/cli/bin/ng cache clean"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "17.1.3",
    "@angular/common": "17.1.3",
    "@angular/compiler": "17.1.3",
    "@angular/core": "17.1.3",
    "@angular/fire": "17.0.1",
    "@angular/forms": "17.1.3",
    "@angular/platform-browser": "17.1.3",
    "@angular/platform-browser-dynamic": "17.1.3",
    "@angular/router": "17.1.3",
    "@angular/service-worker": "17.1.3",
    "@cargamos/angular": "2.1.0",
    "@cds.js/core": "1.19.4",
    "@ngx-translate/core": "15.0.0",
    "@ngx-translate/http-loader": "8.0.0",
    "@sentry/angular-ivy": "7.88.0",
    "file-saver": "^2.0.5",
    "jsbarcode": "^3.11.6",
    "ng-qrcode": "^17.0.0",
    "ngx-progressbar": "^11.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.1.3",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "17.1.3",
    "@angular/compiler-cli": "17.1.3",
    "@commitlint/cli": "^18.6.1",
    "@types/file-saver": "^2.0.7",
    "@types/jest": "^29.5.12",
    "@types/node": "^20.11.17",
    "@typescript-eslint/eslint-plugin": "^7.0.1",
    "@typescript-eslint/parser": "^7.0.1",
    "commitlint-config-jira": "^1.6.4",
    "commitlint-plugin-jira-rules": "^1.6.4",
    "eslint": "^8.56.0",
    "eslint-config-prettier": "^9.1.0",
    "husky": "^9.0.11",
    "jest": "^29.7.0",
    "jest-canvas-mock": "^2.5.2",
    "jest-fetch-mock": "^3.0.3",
    "jest-preset-angular": "14.0.2",
    "lint-staged": "^15.2.2",
    "prettier": "^3.2.5",
    "prettier-plugin-organize-attributes": "^1.0.0",
    "typescript": "~5.3.3"
  }
}
