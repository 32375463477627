import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ShipperUserPermission } from '@constants/shipper-user.constant';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userPermissions: BehaviorSubject<Array<ShipperUserPermission>> = new BehaviorSubject<
    Array<ShipperUserPermission>
  >([]);

  public userPermissions$: Observable<Array<ShipperUserPermission>> =
    this.userPermissions.asObservable();

  public loadPermissions(permissions: Array<ShipperUserPermission>): void {
    this.userPermissions.next(permissions);
  }

  public hasPermissions(permissions: Array<ShipperUserPermission>): boolean {
    return this.userPermissions
      .getValue()
      .some((permission: ShipperUserPermission) => permissions.includes(permission));
  }
}
