import { inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {
  Auth,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  AuthErrorCodes,
} from '@angular/fire/auth';
import { FirebaseError } from '@angular/fire/app';

import { environment as ENV } from '@environments/environment';
import { StorageService } from '@core/services/storage.service';
import { PermissionService } from '@services/permission.service';
import { ErrorDetail, SuccessResponse } from '@cargamos/angular';
import { MeResponse } from '@modules/auth/interfaces/responses/me.response';
import { IdentityPayload } from '@modules/auth/interfaces/payloads/identity-payload.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private me: BehaviorSubject<MeResponse | null> = new BehaviorSubject<MeResponse | null>(null);

  private readonly auth: Auth = inject(Auth);
  private readonly permissionService: PermissionService = inject(PermissionService);
  private readonly storageService: StorageService = inject(StorageService);
  private readonly translateService: TranslateService = inject(TranslateService);

  private readonly http: HttpClient = inject(HttpClient);
  private readonly router: Router = inject(Router);
  private readonly ngZone: NgZone = inject(NgZone);

  public me$: Observable<MeResponse | null> = this.me.asObservable();

  public loadMeSession(me: MeResponse): void {
    this.me.next(me);
    this.permissionService.loadPermissions(me.user.custom_claims.permissions);
  }

  public isUserLoggedIn(): boolean {
    const token: string | null = this.getAuthToken();
    if (token) {
      return !!token.length;
    }

    return false;
  }

  public getTenantIdsByEmail(email: string): Observable<Array<string>> {
    return this.http
      .get<
        SuccessResponse<{ tenants: Array<string> }>
      >(`${ENV.cargamos.apiUrl}/v1/users/${email}/tenants`)
      .pipe(map(response => response?.data?.tenants || []));
  }

  public login(payload: IdentityPayload): Observable<string> {
    this.auth.tenantId = payload.tenantId;
    return from(signInWithEmailAndPassword(this.auth, payload.email, payload.password)).pipe(
      switchMap((user: UserCredential) => from(user?.user?.getIdTokenResult())),
      map(({ token }) => token),
      catchError((error: FirebaseError) =>
        throwError({
          httpStatus: 400,
          errors: this.mapFirebaseErrorToErrorResponse(error),
        })
      )
    );
  }

  public logOut(): void {
    from(signOut(this.auth)).subscribe(() => {
      const email = this.storageService.get('email');
      this.storageService.removeAll();
      if (email) {
        this.storageService.set('email', email);
      }
      this.redirectToLogin();
    });
  }

  public getUserSessionInfo(): Observable<SuccessResponse<MeResponse>> {
    return this.http.get<SuccessResponse<MeResponse>>(`${ENV.cargamos.apiUrl}/v1/me`);
  }

  public redirectToHome(): void {
    this.ngZone.run(() => this.router.navigateByUrl('/admin'));
  }

  public redirectToLogin(): void {
    this.ngZone.run(() => this.router.navigateByUrl('/auth/login'));
  }

  public getTenantIdFromUserAuth(): string {
    return this.auth.currentUser?.tenantId || '';
  }

  public getUserName(): string {
    return this.auth.currentUser?.displayName || '';
  }

  public recoverPassword(email: string): Observable<SuccessResponse> {
    return this.http.post<SuccessResponse>(`${ENV.cargamos.apiUrl}/v1/password/recovery`, {
      email,
    });
  }

  public getAuthToken(): string | null {
    return this.storageService.getAndConvertToString('session');
  }

  public saveAuthToken(session: string): void {
    this.storageService.set('session', session);
  }

  public refreshToken(): Observable<string | null> {
    if (this.auth.currentUser) {
      return from(this.auth.currentUser.getIdTokenResult(true)).pipe(map(({ token }) => token));
    }

    return EMPTY;
  }

  private mapFirebaseErrorToErrorResponse(error: FirebaseError): Array<ErrorDetail> {
    const errorMessage =
      error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER
        ? this.translateService.instant('AUTH.ERRORS.USER_BLOCKED')
        : this.translateService.instant('AUTH.ERRORS.GENERIC_ERROR_LOGIN');

    return [
      {
        code: 'BAD_REQUEST',
        details: '',
        displayable_message: errorMessage,
        location: '',
        message: '',
        parameter: '',
      },
    ];
  }
}
